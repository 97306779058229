import React from "react";
import styled from "styled-components";
import { Wrapper } from "./style/global-styles";
import { motion as m, useReducedMotion } from "framer-motion";
import { useViewportScroll, useTransform } from "framer-motion";
import { clampBuilder, wrapHeadlineInArray } from "../functions/util";
import Box from "./box";
import { animation } from "../data/baseTheme";

function MotionHeadline({ headline }) {
  const { scrollY } = useViewportScroll();
  const rotateCW = useTransform(scrollY, [0, 2000], ["0deg", "360deg"]);
  const rotateCCW = useTransform(scrollY, [0, 2000], ["0deg", "-360deg"]);
  const reduceMotion = useReducedMotion();

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren: 0.05,
        duration: 2,
        ease: [animation.timingFunction.js],
      },
    },
  };

  const textVariants = {
    initial: {
      opacity: 0,
      rotate: "-3deg",
      y: "140%",
    },
    active: {
      opacity: 1,
      rotate: "0deg",
      y: "0%",
      transition: {
        duration: 0.9,
        ease: [animation.timingFunction.js],
      },
    },
    hover: {
      opacity: 1,
      rotate: "40deg",
      scale: 1.1,
    },
    exit: {
      opacity: 0,
      rotate: "3deg",
      y: "-140%",
    },
  };

  const headlineArray = wrapHeadlineInArray(headline);

  return (
    <m.h1
      variants={textContainerVariants}
      initial={reduceMotion ? "active" : "initial"}
      animate="active"
      exit="exit"
    >
      {headlineArray.map((item, i) => {
        if (item.text.includes(":")) {
          const isStar = item.text.includes("star");
          const isSmile = item.text.includes("smile");
          const style = isStar
            ? { rotate: rotateCW }
            : isSmile
            ? { rotate: rotateCCW }
            : null;
          return (
            <span key={i} className="text-mask">
              <m.span
                variants={textVariants}
                style={style}
                data-text={item.text}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              />
            </span>
          );
        } else if (item.text.includes("<em>")) {
          const cleanText = item.text.replace("<em>", "").replace("</em>", "");
          const arr = cleanText.split(" ");
          return arr.map((word, idx) => (
            <span key={idx} className="text-mask">
              <m.em variants={textVariants}>{word}</m.em>
            </span>
          ));
        } else {
          return (
            <span key={i} className="text-mask">
              <m.span variants={textVariants}>{item.text}</m.span>
            </span>
          );
        }
      })}
    </m.h1>
  );
}

export function HomeHeroSection({ data }) {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  return (
    <Wrapper>
      <Box>
        <HeroHeadline
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 1 }}
          bugs={data.headline_bugs}
        >
          <MotionHeadline headline={data.content.html} />
        </HeroHeadline>
      </Box>
    </Wrapper>
  );
}

function createBugStyles(array) {
  const result = array.map(({ key, image: { url } }, i) => {
    return `
span[data-text="${key}"] {
  background-image: url(${url});
}
    `;
  });

  return result;
}

const HeroHeadline = styled(m.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-top: 8rem;
  margin-bottom: 4rem;
  min-height: 30vw;
  text-align: center;

  @media ${(props) => props.theme.device.tablet} {
    margin-top: 9rem;
    margin-bottom: 8rem;
    min-height: 30vw;
  }

  @media ${(props) => props.theme.device.laptop} {
    margin-top: 11vw;
    margin-bottom: 8vw;
    min-height: 30vw;
  }

  h1 {
    max-width: 13em;
    font-size: ${clampBuilder(1.85, 10.75)};
    line-height: 1 !important;

    @media ${(props) => props.theme.device.tablet} {
      line-height: 108%;
    }

    em {
      font-family: "Sequel_100 Wide W05_95", sans-serif;
      font-style: normal;
      font-size: ${clampBuilder(1.35, 8.25)};
      text-transform: uppercase;
    }

    span.text-mask {
      position: relative;
      overflow: hidden;
      display: inline-block;
      padding: 0.2em 0.12em;
      margin-bottom: -0.4em;

      > span,
      > em {
        display: inline-block;
        position: relative;
      }
    }

    em {
      margin-bottom: -0.4em;
    }

    span[data-text] {
      width: 0.8em;
      display: inline-block;
      position: relative;
      margin-bottom: -0.03em;
      background-size: 0.8em 0.8em;
      height: 0.8em;
      width: 0.8em;
    }

    ${({ bugs }) => createBugStyles(bugs)}
  }
`;
