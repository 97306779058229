import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

/**
 *  Components */
import Box from './box';
import ImageBox from './image-box';
import VideoBox from './video-box';
import Tick from './tick';
import Link from './link';
import AnimatedText from './animated-text';

/**
 *  JS */
import { clampBuilder, hoverChange } from '../functions/util';
import { LineAnimated } from './line-animated';
import { Wrapper } from './style/global-styles';

function ImageBoxArray({ imageArray }) {
  const [active, setActive] = useState(0);
  const arrIdx = imageArray.length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((active) => (active === arrIdx ? 0 : active + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [arrIdx]);

  const boxVariants = {
    hidden: {
      opacity: 0,
      scale: 1,
      x: '0%'
    },
    visible: {
      opacity: 1,
      scale: 1,
      x: '0%'
    }
  };

  return (
    <ImageArrayOuter className="image-outer">
      {imageArray.map((image, i) => {
        return (
          <ImageBox
            key={i}
            className="image"
            variants={boxVariants}
            transition={{
              ease: [0.1, 0.25, 0.3, 1],
              duration: 1
            }}
            animate={i === active ? 'visible' : 'hidden'}
            fluid={image.fluid}
          />
        );
      })}
    </ImageArrayOuter>
  );
}

const ImageArrayOuter = styled.div`
  position: relative;
  height: auto;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

function FeaturedImage({ video, images: { image, image_2, image_3 } }) {
  if (video.url !== '') {
    return (
      <div className="video-outer">
        <VideoBox className="video" video={video} />
      </div>
    );
  } else {
    const imageArray =
      image.fluid && image_2.fluid && image_3.fluid
        ? [image, image_2, image_3]
        : image.fluid && image_2.fluid
        ? [image, image_2]
        : image.fluid
        ? [image]
        : [];
    if (imageArray.length > 1) {
      return <ImageBoxArray imageArray={imageArray} />;
    } else {
      return <ImageBox className="image" fluid={image.fluid} />;
    }
  }
}

export function HomeProjectsFeature({ data }) {
  return (
    <>
      {data.featured_projects.map(
        ({ project, image, image_2, image_3, video }, i) => {
          return (
            <ProjectFeatureItem
              key={i}
              onClick={() => navigate(project.document.url)}
              onMouseEnter={() => hoverChange('project')}
              onMouseLeave={() => hoverChange(null)}
              data-item={i + 1}
            >
              <TextWrap>
                <AnimatedText
                  text={project.document.data.project_name}
                  className="h2"
                />
                <AnimatedText
                  text={project.document.data.title}
                  className="h5 sequel"
                />
                <span className="line" />
                <AnimatedText
                  text={project.document.data.date}
                  className="h5"
                />
              </TextWrap>
              <FeaturedImage
                video={video}
                images={{ image: image, image_2: image_2, image_3: image_3 }}
              />
            </ProjectFeatureItem>
          );
        }
      )}

      <LinkSection className="ticker">
        <LineWrap>
          <LineAnimated />
        </LineWrap>
        <TickerLink to="/work" hoverData="project">
          <Tick>
            {() => (
              <>
                <h1 className="sequel">All</h1> <h1>Work </h1>
              </>
            )}
          </Tick>
        </TickerLink>
        <LineWrap>
          <LineAnimated />
        </LineWrap>
      </LinkSection>
    </>
  );
}

const LineWrap = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TickerLink = styled(Link)`
  margin: 5vw 0;
  display: inline-flex;

  &::before,
  &::after {
    visibility: hidden;
  }
`;

const LinkSection = styled(Box)`
  padding: 4vw 0;
  margin: 6vw 0;
  position: relative;

  a:hover {
    h1 {
      color: #ffff00 !important;

      &.sequel {
        -webkit-text-stroke-color: transparent;
        color: white !important;
      }
    }
  }

  &::before,
  &::after {
    visibility: hidden;
  }

  h1 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: transparent;
    font-size: ${clampBuilder(3, 8)};
    margin: 0 !important;
    will-change: color, -webkit-text-stroke-color;

    &.sequel {
      transform: translateY(0.08em);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
      color: transparent !important;
      will-change: color, -webkit-text-stroke-color;
    }
  }
`;

const TextWrap = styled.div`
  height: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  text-align: left;

  @media ${(props) => props.theme.device.tablet} {
    &::before,
    &::after {
      content: '—';
      color: var(--post-accent-color);
      position: absolute;
    }

    &::before {
      top: -0.55em;
    }

    &::after {
      bottom: -0.55em;
    }
  }

  @media ${(props) => props.theme.device.laptop} {
    min-width: 25%;
  }

  .h2 {
    margin-bottom: 0.35em;
  }
`;

const ProjectFeatureItem = styled(Box)`
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: 8vw 0;

  &:hover {
    img,
    video {
      transform: scale3d(1.05, 1.05, 1.05);

      @media (hover: none) {
        transform: scale3d(1);
      }
    }
  }

  img,
  video {
    transition: transform ${(props) => props.theme.animation.duration[300].css}
        ${(props) => props.theme.animation.timingFunction.css},
      opacity ${(props) => props.theme.animation.duration[100].css}
        ${(props) => props.theme.animation.timingFunction.css} !important;
  }

  span.line {
    margin: 0 0 1rem;
    position: relative;
    height: 1px;
    width: 13px;
    background: white;
    transform-origin: center left;
    transition: width ${(props) => props.theme.animation.duration[200].css}
      ${(props) => props.theme.animation.timingFunction.css};

    @media ${(props) => props.theme.device.tablet} {
      margin: 1.5rem 0 1.65rem;
    }
  }

  .video-outer {
    overflow: hidden;
  }

  .video {
    width: 100%;
    height: 100%;
  }

  .image,
  .image-outer,
  .video-outer {
    width: 100vw;
    height: auto;
    flex-grow: 1;

    @media ${(props) => props.theme.device.tablet} {
      width: 60vw;
    }
  }

  .image-outer,
  .video-outer {
    height: 60vw;

    @media ${(props) => props.theme.device.tablet} {
      height: 36vw;
    }
  }

  ${TextWrap} {
    width: calc(100% - 4rem);
    margin: 1rem 2rem 1rem;
  }

  &[data-item='1'],
  &[data-item='3'],
  &[data-item='5'] {
    flex-direction: column-reverse;

    @media ${(props) => props.theme.device.tablet} {
      flex-direction: row-reverse;

      ${TextWrap} {
        width: 20%;
        margin-left: 4vw;
        margin-right: 10vw;
      }
    }
  }

  &[data-item='2'],
  &[data-item='4'],
  &[data-item='6'] {
    flex-direction: column-reverse;

    @media ${(props) => props.theme.device.tablet} {
      flex-direction: row;

      ${TextWrap} {
        width: 20%;
        margin-right: 4vw;
        margin-left: 8vw;
      }
    }
  }
`;
