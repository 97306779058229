import React from "react";
import { graphql } from "gatsby";

import { HomeHeroSection } from "../components/home-hero-section";
import { HomeProjectsFeature } from "../components/home-projects-feature";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicHomePage: { data },
  },
}) {
  return (
    <>
      <HomeHeroSection data={data} />
      <HomeProjectsFeature data={data} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query HomeQuery {
    prismicHomePage {
      id
      data {
        headline_bugs {
          key
          image {
            url(imgixParams: { maxWidth: 70 })
          }
        }
        content {
          text
          html
          raw
        }
        featured_projects {
          project {
            document {
              ... on PrismicPost {
                id
                data {
                  title
                  project_name
                  date(formatString: "YYYY")
                  work_for {
                    text
                  }
                }
                url
              }
            }
          }
          video {
            url
          }
          image {
            fluid(
              placeholderImgixParams: { blur: 1600, px: 1 }
              maxWidth: 1200
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
          image_3 {
            fluid(
              placeholderImgixParams: { blur: 1600, px: 1 }
              maxWidth: 1200
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
          image_2 {
            fluid(
              placeholderImgixParams: { blur: 1600, px: 1 }
              maxWidth: 1200
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        title
      }
    }
  }
`;
